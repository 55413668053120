<template>
  <div v-if="wkbInspectionPlanStatus === 'success'" class="d-flex flex-column">
    <module-navigation-bar title="Keuringsplan">
      <template #module-nav-actions>
        <v-tabs
          v-model="wkbInspectionPlanTab"
          height="46"
          style="width: auto"
          background-color="transparent"
        >
          <v-tab> Overzicht </v-tab>
        </v-tabs>
      </template>
      <template #module-nav-logo>
        <img
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <div class="flex-1 flex-scroll-height overflow-y-auto">
      <router-view />
    </div>
  </div>
  <div v-else class="d-flex justify-center align-center">
    <ant-loading />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { DHME_WKB_LIBRARY, DHME_WKB_INSPECTION_PLAN } from '@/modules/modules';
import AntLoading from '@/components/AntLoading.vue';
import DHMEWKBAnalysisRisks from '@/modules/daiwa-house-modular-europe/WkbInspectionLibrary/DhmeWkbInspectionPlanRisks.vue';
import ResizeHorizontal from '@/components/ResizeHorizontal.vue';
import DHMEWKBAnalysisRiskDetail from '@/modules/daiwa-house-modular-europe/WkbInspectionLibrary/DhmeWkbInspectionPlanRisksDetail.vue';

export default {
  name: 'DHMEWkbInspectionPlan',
  components: {
    AntLoading,
    ModuleNavigationBar,
  },
  data: () => {
    return {
      wkbInspectionPlanTab: 0,
    };
  },
  computed: {
    ...mapGetters(['project', 'wkbInspectionPlanStatus']),
  },
  watch: {
    wkbInspectionPlanTab(value) {
      switch (value) {
        case 0: {
          this.$router.push('overview');
          break;
        }
        case 1: {
          this.$router.push('planning');
          break;
        }
      }
    },
  },
  mounted() {
    this.$store
      .dispatch('fetchWKBInspectionPlanModuleData', {
        projectId: this.project.id,
        moduleId: this.project.modules.find(
          (module) => module.route === DHME_WKB_INSPECTION_PLAN
        ).id,
        sessionId: this.$route.params.sessionId ?? null,
      })
      .then(() => {
        if (this.$route.name === DHME_WKB_INSPECTION_PLAN) {
          this.$router.push({ name: `${DHME_WKB_INSPECTION_PLAN}-overview` });
        } else {
          switch (this.$route.name) {
            case `${DHME_WKB_INSPECTION_PLAN}-overview`:
              this.wkbInspectionPlanTab = 0;
              break;
            case `${DHME_WKB_INSPECTION_PLAN}-planning`:
              this.wkbInspectionPlanTab = 1;
              break;
          }
        }
      });
  },
};
</script>
